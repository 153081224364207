







































@import "../../../assets/scss/_variables.scss";
.das-menu-ed{
  li{
    padding: 10px 12px 10px 48px;
    position: relative;
    font-weight: 500;
    font-size: 16px;
    color: $color3;
    transition: $transition;
    margin-bottom: 8px;
    border-radius: 8px;
    background-color: rgba(1,1,1,0);
    cursor: pointer;
    .icon{
      width: 24px;
      height: 24px;
      line-height: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 12px;
      svg{
        max-width: 100%;
      }
      img{
        max-width: 100%;
      }
      .fill-grey{
        fill: #767676;
        transition: $transition;
      }
    }
    &.is-active{
      background-color: $color4;
      color: $color1;
      .icon{
        .fill-grey{
          fill: $color1;
        }
      }
    }
  }
}
/*==========  Hover  ==========*/

@media (min-width: 1200px){
  .das-menu-ed li:hover{
    background-color: $color4;
  }
}

